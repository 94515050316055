<template>
  <fragment>
    <b-row v-if="labelPosition === 'left'" class="form-row form-group">
      <b-col v-if="label" sm="3">
        <label :for="id">
          {{ label }}
          <span v-if="required" class="trazo-label-required">*</span>
        </label>
      </b-col>
      <b-col v-if="label" sm="9">
        <slot />
        <b-form-text class="trazo-text-description" v-if="description">
          {{ description }}
        </b-form-text>
      </b-col>
      <b-col v-else sm="12">
        <slot />
        <b-form-text class="trazo-text-description" v-if="description">
          {{ description }}
        </b-form-text>
      </b-col>
    </b-row>
    <b-row v-if="labelPosition === 'top'" class="form-row form-group">
      <b-col v-if="label" sm="12">
        <label :for="id">
          {{ label }}
          <span v-if="required" class="trazo-label-required">*</span>
        </label>
      </b-col>
      <b-col sm="12">
        <slot />
        <b-form-text class="trazo-text-description" v-if="description">
          {{ description }}
        </b-form-text>
      </b-col>
    </b-row>
  </fragment>
</template>

<script>
import Vue from "vue";

export default Vue.component("trazo-form-label-slot", {
  props: {
    label: { type: String, default: "" },
    id: { type: String, default: "" },
    description: { type: String, default: "" },
    required: { type: Boolean, default: false },
    labelPosition: { type: String, default: "left" }
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      }
    }
  }
});
</script>