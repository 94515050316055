<template>
  <!--begin: Wizard Step 4-->
  <div class="pb-5 row" data-wizard-type="step-content">
    <div class="col-lg-8">
      <b-form-group label="Cliente" label-for="input-client-2">
        <b-row>
          <b-col sm="6">
            <b-form-input
              id="input-client-2"
              :value="project.client ? project.client.social_name : ''"
              disabled
            ></b-form-input>
          </b-col>
          <b-col sm="6">
            <b-form-input
              id="input-client-2"
              :value="project.client ? project.client.code : ''"
              disabled
            ></b-form-input>
          </b-col>
        </b-row>
      </b-form-group>
      <b-form-group label="Proyecto" label-for="input-description-2">
        <b-row>
          <b-col sm="6">
            <b-form-input
              id="input-description-2"
              :value="project.description"
              disabled
            />
          </b-col>
          <b-col sm="6">
            <b-form-input
              id="input-description-2"
              :value="project.description"
              disabled
            />
          </b-col>
        </b-row>
      </b-form-group>
    </div>
    <b-tabs content-class="mt-3">
      <b-tab active>
        <template #title>
          <inline-svg
            height="1.4em"
            width="1.4em"
            class="bi-folder-fill wizard-icon b-icon bi"
            fill="currentColor"
            src="media/svg/icons/Text/Article.svg"
          />
          Partida presupuestal
        </template>

        <div class="d-flex justify-content-between mb-4 mt-5">
          <h3>PARTIDAS PRESUPUESTALES</h3>
          <div class="d-flex justify-content-end">
            <b-form-input
              class="col-8"
              placeholder="Buscar"
              v-model="searchWord"
            />
          </div>
        </div>
        <b-table
          :per-page="itemsPerPage"
          :items="subProjects"
          :fields="fields"
          head-variant="dark"
        >
          <template #cell(status)="data">
            {{ calculateStatus(data.status) }}
          </template>
          <template #cell(actions)="data">
            <div class="h5 mb-0">
              <b-button size="sm" variant="light">
                <b-icon icon="pencil-square"></b-icon>
              </b-button>
              <b-button size="sm" variant="light">
                <b-icon icon="eye-fill"></b-icon>
              </b-button>
              <b-button size="sm" variant="light">
                <b-icon icon="three-dots-vertical"></b-icon>
              </b-button>
            </div>
          </template>
        </b-table>
        <div class="d-flex justify-content-between">
          <div>
            Registros 1 -
            {{
              subProjects.length > itemsPerPage
                ? itemsPerPage
                : subProjects.length
            }}
            de {{ subProjects.length }} | Por página
            <trazo-dropdown
              v-model="itemsPerPage"
              :fields="fieldsPages"
              variant="light"
              id="dropdown-items-by-page"
              :text="itemsPerPage"
              :default="itemsPerPage"
            />
          </div>
          <b-pagination
            v-model="currentPage"
            :total-rows="subProjects.length"
            :per-page="itemsPerPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </b-tab>
      <b-tab>
        <template #title>
          <inline-svg
            height="1.4em"
            width="1.4em"
            class="bi-folder-fill wizard-icon b-icon bi"
            fill="currentColor"
            src="media/svg/icons/Text/Align-left.svg"
          />
          Centro de Costo
        </template>
        <div class="d-flex justify-content-between mb-4 mt-5">
          <h3>CENTROS DE COSTOS</h3>
          <div class="d-flex justify-content-end">
            <b-button
              v-b-modal.modal-form-cost-center
              @click="showModalFormCostCenter = true"
              class="mr-2 col-3"
            >
              <b-icon icon="plus"></b-icon>
              Nuevo
            </b-button>
            <b-form-input
              class="col-8"
              placeholder="Buscar"
              v-model="searchWord"
            />
          </div>
        </div>
        <b-table
          :per-page="itemsPerPage"
          :items="subProjects"
          :fields="fields"
          head-variant="dark"
        >
          <template #cell(status)="data">
            {{ calculateStatus(data.status) }}
          </template>
          <template #cell(actions)="data">
            <div class="h5 mb-0">
              <b-button size="sm" variant="light" @click="editCostCenter(data)">
                <b-icon icon="pencil-square"></b-icon>
              </b-button>
              <b-button size="sm" variant="light">
                <b-icon icon="eye-fill"></b-icon>
              </b-button>
              <b-button size="sm" variant="light">
                <b-icon icon="trash-fill"></b-icon>
              </b-button>
              <b-button size="sm" variant="light">
                <b-icon icon="three-dots-vertical"></b-icon>
              </b-button>
            </div>
          </template>
        </b-table>
        <div class="d-flex justify-content-between">
          <div>
            Registros 1 -
            {{
              subProjects.length > itemsPerPage
                ? itemsPerPage
                : subProjects.length
            }}
            de {{ subProjects.length }} | Por página
            <trazo-dropdown
              v-model="itemsPerPage"
              :fields="fieldsPages"
              variant="light"
              id="dropdown-items-by-page"
              :text="itemsPerPage"
              :default="itemsPerPage"
            />
          </div>
          <b-pagination
            v-model="currentPage"
            :total-rows="subProjects.length"
            :per-page="itemsPerPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </b-tab>
    </b-tabs>
    <modal-form-budge-items
      v-if="showModalFormCostCenter"
      v-on:close="
        showModalFormCostCenter = false;
        currentCostCenter = null;
      "
      :costCenter="currentCostCenter"
    />
  </div>
  <!--end: Wizard Step 4-->
</template>

<script>
import "vue-multiselect/dist/vue-multiselect.min.css";

import { mapGetters } from "vuex";
import ModalFormCostCenter from "../ModalFormCostCenter";
import TrazoDropdown from "@/view/content/TrazoDropdown";

export default {
  props: ["submited"],
  data() {
    return {
      showModalFormCostCenter: false,
      currentCostCenter: null,
      fields: [
        { key: "actions", label: "Acciones" },
        { key: "code", label: "Código", sortable: true },
        { key: "detail", label: "Detalle", sortable: true },
        { key: "quantity", label: "Cantidad", sortable: true },
        { key: "state", label: "Estado", sortable: true }
      ],
      fieldsPages: [15, 20, 25, 30, 40, 50],
      itemsPerPage: "25",
      currentPage: 1,
      searchWord: ""
    };
  },
  name: "Project-Step-4",
  computed: {
    ...mapGetters(["isTochedFormProject", "clients", "project", "subProjects"])
  },
  watch: {
    // This would be called anytime the value of title changes
    submited(newValue, oldValue) {
      if (newValue != oldValue && newValue === 2) {
        // Send data to procces
        this.$emit("onSubmitStep", {
          clientOrder: this.clientOrder,
          itemsManager: this.itemsManager,
          fileItemsManager: this.fileItemsManager
        });
      }
    }
  },
  methods: {
    onSelect(value, itemName) {
      this.$data[itemName] = value;
    },
    // events
    onButtonFileItemsManager() {
      this.$refs.fileItemsManager.click();
    },
    onFileChanged(e, itemName, inputName = null) {
      this.$data[itemName] = e.target.files[0];
      if (inputName) {
        this.$data[inputName] = this.$data[itemName].name;
      }
    },
    // Validators
    isInvalidSelectNull(selectedItems) {
      return this.isTochedFormProject && selectedItems === null;
    },
    editCostCenter(data) {
      this.currentCostCenter = data;
      this.showModalFormCostCenter = true;
    }
  },
  components: {
    "trazo-dropdown": TrazoDropdown,
    "modal-form-budge-items": ModalFormCostCenter
  }
};
</script>
