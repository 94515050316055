<template>
  <b-dropdown variant="light" id="dropdown-1" :text="text">
    <b-dropdown-item
      @click="onClick(field)"
      v-for="field in fields"
      :key="field"
    >
      {{ field }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import Vue from "vue";

export default Vue.component("trazo-dropdown", {
  props: {
    text: { type: String, default: "" },
    id: { type: String, default: "" },
    variant: { type: String, default: "light" },
    fields: { type: Array },
    default: { type: String }
  },
  computed: {
    internalValue: {
      get() {
        return this.internalValue;
      },
      set(v) {
        this.$emit("input", v);
      }
    }
  },
  mounted() {
    // this.$root.$on("bv::dropdown::show", (bvEvent) => {
    // });
  },
  methods: {
    onClick(field) {
      this.$emit("input", `${field}`);
    }
  }
});
</script>
