<template>
  <b-modal
    header-class="modalFormHeader"
    id="modal-form-cost-center"
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
  >
    <template #modal-header="{ close }">
      <div class="modalFormHeaderCloseWrap">
        <b-button variant="light" @click="cancel(close)">
          <b-icon icon="x" />
        </b-button>
      </div>
      <div class="modalFormHeaderTitleWrap">
        <div>
          {{ textModal }}
        </div>
        <div>
          <b-button size="sm" variant="light" @click="cancel(close)">
            <b-icon icon="arrow-counterclockwise" />
          </b-button>
          <b-button size="sm" variant="light" @click="onSubmit">
            Guardar
          </b-button>
        </div>
      </div>
    </template>
    <b-row class="justify-content-center">
      <form class="full" id="formCostCenter" ref="formCostCenter">
        <trazo-form-label-slot
          labelPosition="top"
          :required="true"
          label="Partida"
        >
          <div
            :class="{
              invalid: isInvalidSelectNull(subProject)
            }"
          >
            <a-multiselect
              name="subProject"
              track-by="code"
              label="detail"
              v-model="subProject"
              placeholder="Seleccione"
              :options="subProjects"
              :allow-empty="false"
              @select="onSelect($event, 'subProject')"
            />
            <input
              type="hidden"
              name="subProject_hidden"
              v-model="subProject_hidden"
            />
          </div>
        </trazo-form-label-slot>
        <trazo-form-input
          id="input-costCenterCode"
          v-model="costCenterCode"
          name="costCenterCode"
          label="Centro de costos"
          :max="100"
          :required="true"
          placeholder="Ingrese el CECO"
          labelPosition="top"
        />
        <trazo-form-label-slot
          labelPosition="top"
          :required="true"
          label="Detalle de Centro de Costo"
        >
          <div
            :class="{
              invalid: isInvalidSelectNull(costCenterDetail)
            }"
          >
            <a-multiselect
              name="costCenterDetail"
              track-by="id"
              label="name"
              v-model="costCenterDetail"
              placeholder="Seleccione"
              :options="costCenterDetails"
              :allow-empty="false"
              @select="onSelect($event, 'costCenterDetail')"
            />
            <input
              type="hidden"
              name="costCenterDetail_hidden"
              v-model="costCenterDetail_hidden"
            />
          </div>
        </trazo-form-label-slot>
      </form>
    </b-row>
  </b-modal>
</template>

<script>
import Vue from "vue";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";
import TrazoFormLabelSlot from "@/view/content/TrazoFormLabelSlot";
import TrazoFormInput from "@/view/content/TrazoFormInput";
import { mapGetters } from "vuex";

export default Vue.component("modal-form-cost-center", {
  data() {
    return {
      textModal: "NUEVO CENTRO DE COSTO",

      subProject: {},
      subProject_hidden: "",

      costCenterCode: "",
      costCenterDetail: "",
      costCenterDetail_hidden: "",

      isTouchedForm: false,

      // de donde viene?
      costCenterDetails: [
        {
          id: 1,
          name: "MATERIALES"
        },
        {
          id: 2,
          name: "OBRAS"
        }
      ]
    };
  },
  props: ["costCenter"],
  watch: {
    // This would be called anytime the value of title changes
    costCenter(newValue, oldValue) {
      if (newValue != oldValue) {
        console.log("Actualizando");
        // this.setData();
      }
    }
  },
  computed: {
    ...mapGetters(["subProjects", "costCenters"])
  },
  methods: {
    cancel(close) {
      close();
      this.$emit("close");
    },
    onSubmit() {
      this.fv.validate();
      this.fv.on("core.form.valid", () => {
        console.log("valido");
      });
      this.fv.on("core.form.invalid", () => {
        console.log("0inval");
        this.isTouchedForm = true;
      });
    },
    onSelect(value, itemName) {
      this.$data[itemName] = value;
      if ("id" in value) {
        console.log("`${itemName}_hidden`", `${itemName}_hidden`);
        this.$data[`${itemName}_hidden`] = value.id;
      }
    },
    // Validators
    isInvalidSelectNull(selectedItems) {
      return this.isTouchedForm && selectedItems === null;
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.costCenter) {
        // this.code = this.costCenter.code;
        // this.detail = this.costCenter.detail;
        // this.quantity = this.costCenter.quantity + "";
        // this.textModal = "EDITAR PARTIDA";
      }
      const formCostCenter = KTUtil.getById("formCostCenter");
      this.fv = formValidation(formCostCenter, {
        fields: {
          costCenterCode: {
            validators: {
              notEmpty: {
                message: "El centro de costo es obligatorio"
              }
            }
          },
          subProject_hidden: {
            validators: {
              notEmpty: {
                message: "La partida es obligatoria"
              }
            }
          },
          costCenterDetail_hidden: {
            validators: {
              notEmpty: {
                message: "El detalle de centor de costo es obligatorio"
              }
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          submitButton: new SubmitButton(),
          bootstrap: new Bootstrap()
        }
      });
    }, 300);
  },
  components: {
    "trazo-form-input": TrazoFormInput,
    "trazo-form-label-slot": TrazoFormLabelSlot,
  }
});
</script>
