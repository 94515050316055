<template>
  <b-modal
    header-class="modalFormHeader"
    id="modal-sub-project-detail"
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
  >
    <template #modal-header="{ close }">
      <div class="modalFormHeaderCloseWrap">
        <b-button variant="light" @click="cancel(close)">
          <b-icon icon="x" />
        </b-button>
      </div>
      <div class="modalFormHeaderTitleWrap">
        <div>
          {{ textModalSubProjectDetail }}
        </div>
        <div>
          <b-button size="sm" variant="light" @click="cancel(close)">
            <b-icon icon="arrow-counterclockwise" />
          </b-button>
          <b-button size="sm" variant="light" @click="onSubmit">
            Guardar
          </b-button>
        </div>
      </div>
    </template>
    <b-row class="justify-content-center">
      <form class="full" id="formSubproject" ref="formSubproject">
        <trazo-form-input
          id="input-code"
          v-model="code"
          name="code"
          label="Código"
          :max="100"
          :required="true"
          placeholder="Ingrese código de Partida"
          labelPosition="top"
        />
        <trazo-form-input
          id="input-detail"
          v-model="detail"
          name="detail"
          label="Detalle"
          :max="100"
          :required="true"
          labelPosition="top"
        />
        <trazo-form-input
          id="input-quantity"
          v-model="quantity"
          label="Cantidad"
          name="quantity"
          :required="true"
          labelPosition="top"
          type="number"
        />
      </form>
    </b-row>
  </b-modal>
</template>

<script>
import Vue from "vue";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";

import TrazoFormInput from "@/view/content/TrazoFormInput";
// import TrazoFormLabelSlot from "@/view/content/TrazoFormLabelSlot";
// import TrazoDropdown from "@/view/content/TrazoDropdown";

export default Vue.component("modal-form-sub-project", {
  data() {
    return {
      textModalSubProjectDetail: "NUEVA PARTIDA",
      code: "",
      detail: "",
      quantity: ""
    };
  },
  props: ["subProject"],
  watch: {
    // This would be called anytime the value of title changes
    subProject(newValue, oldValue) {
      if (newValue != oldValue) {
        console.log("Actualizando");
        // this.setData();
      }
    }
  },
  methods: {
    cancel(close) {
      close();
      this.$emit("close");
    },
    onSubmit() {
      this.fv.validate();
      this.fv.on("core.form.valid", () => {
        console.log("valido");
      });
      this.fv.on("core.form.invalid", () => {
        console.log("0inval");
      });
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.subProject) {
        this.code = this.subProject.code;
        this.detail = this.subProject.detail;
        this.quantity = this.subProject.quantity + "";
        this.textModalSubProjectDetail = "EDITAR PARTIDA";
      }
      const formSubproject = KTUtil.getById("formSubproject");
      this.fv = formValidation(formSubproject, {
        fields: {
          code: {
            validators: {
              notEmpty: {
                message: "El código es obligatorio"
              }
            }
          },
          detail: {
            validators: {
              notEmpty: {
                message: "El detalle es obligatorio"
              }
            }
          },
          quantity: {
            validators: {
              notEmpty: {
                message: "El detalle es obligatorio"
              },
              integer: {
                message: "El detalle debe de ser un número entero."
              },
              between: {
                inclusive: true,
                min: 1,
                max: 4,
                message: "El minimo es 1 y el máxmimo es 4"
              }
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          submitButton: new SubmitButton(),
          bootstrap: new Bootstrap()
        }
      });
    }, 300);
  },
  components: {
    "trazo-form-input": TrazoFormInput
  }
});
</script>
