<template>
  <fragment>
    <b-row
      class="form-row form-group trazo-form-group"
      v-if="labelPosition === 'left'"
    >
      <b-col sm="3">
        <label :for="id">
          {{ label }}
          <span v-if="required" class="trazo-label-required">*</span>
        </label>
      </b-col>
      <b-col class="trazo-input-wrap" :sm="this.$slots['default'] ? 8 : 9">
        <div>
          <b-form-input
            v-model="internalValue"
            @keydown="onKeyDown"
            v-bind="$attrs"
          />
          <b-form-text class="text-right">{{
            counter(value, max)
          }}</b-form-text>
          <b-form-text class="trazo-text-description" v-if="description">
            {{ description }}
          </b-form-text>
        </div>
      </b-col>
      <b-col v-if="this.$slots['default']" sm="1">
        <slot />
      </b-col>
    </b-row>
    <b-form-group
      class="group-vertical"
      v-if="labelPosition === 'top'"
      :label="label"
      :label-for="id"
    >
      <b-form-input v-bind="$attrs" v-model="internalValue" />
      <b-form-text v-if="max !== 0" class="text-right">{{
        counter(value, max)
      }}</b-form-text>
      <b-form-text class="trazo-text-description" v-if="description">
        {{ description }}
      </b-form-text>
    </b-form-group>
  </fragment>
</template>

<script>
import Vue from "vue";

export default Vue.component("trazo-form-imput", {
  props: {
    value: { type: String, default: "" },
    id: { type: String, default: "" },
    max: { type: Number, default: 0 },
    label: { type: String, default: "" },
    description: { type: String, default: "" },
    required: { type: Boolean, default: false },
    labelPosition: { type: String, default: "left" }
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      }
    }
  },
  methods: {
    counter(value, max) {
      return `${value.length}/${max}`;
    },
    onKeyDown(evt) {
      if (this.value.length >= this.max) {
        if (evt.keyCode >= 48 && evt.keyCode <= 90) {
          evt.preventDefault();
          return;
        }
      }
    }
  }
});
</script>
