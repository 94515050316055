<template>
  <!--begin: Wizard Step 1-->
  <div
    data-wizard-state="current"
    class="pb-5 row justify-content-center"
    data-wizard-type="step-content"
  >
    <div class="col-lg-7 m-auto">
      <trazo-form-input
        id="input-code"
        v-model="code"
        name="code"
        label="Código"
        :max="30"
        ref="code"
        :required="true"
        placeholder="Ingrese código de Navasoft"
      />
      <trazo-form-input
        id="input-abbreviation"
        v-model="abbreviation"
        name="abbreviation"
        ref="abbreviation"
        label="Abreviatura"
        :max="30"
        :required="true"
      />
      <trazo-form-input
        id="input-description"
        v-model="description"
        name="description"
        ref="description"
        label="Descripción"
        :max="100"
        :required="true"
      />
      <trazo-form-label-slot :required="true" label="Cliente">
        <div
          :class="{
            invalid: isInvalidSelectNull(client)
          }"
        >
          <a-multiselect
            v-if="clients"
            track-by="id"
            label="social_name"
            name="client"
            v-model="client"
            placeholder="Seleccione Cli"
            :options="clients"
            :allow-empty="false"
            @select="onSelect($event, 'client')"
          />
          <input type="hidden" name="client_hidden" v-model="client_hidden" />
        </div>
      </trazo-form-label-slot>
      <trazo-form-label-slot :required="true" label="Jefe de proyecto">
        <div
          :class="{
            invalid: isInvalidSelectNull(projectManager)
          }"
        >
          <a-multiselect
            v-if="projectManagers"
            track-by="id"
            label="full_name"
            name="projectManager"
            v-model="projectManager"
            placeholder="Seleccione un Responsable de Proyecto"
            :options="projectManagers"
            :allow-empty="false"
            @select="onSelect($event, 'projectManager')"
          />
          <input
            type="hidden"
            name="projectManager_hidden"
            v-model="projectManager_hidden"
          />
        </div>
      </trazo-form-label-slot>
    </div>
  </div>
  <!--end: Wizard Step 1-->
</template>

<script>
import "vue-multiselect/dist/vue-multiselect.min.css";
import TrazoFormInput from "@/view/content/TrazoFormInput";
import TrazoFormLabelSlot from "@/view/content/TrazoFormLabelSlot";

import { mapGetters } from "vuex";

export default {
  name: "Project-Step-1",
  props: ["submited"],
  data() {
    return {
      code: "",
      abbreviation: "",
      description: "",
      projectManager: null,
      client: null,

      // Hiden inputs validato of selects
      client_hidden: "",
      projectManager_hidden: ""
    };
  },
  watch: {
    // This would be called anytime the value of title changes
    submited(newValue, oldValue) {
      if (newValue != oldValue && newValue === 1) {
        // Send data to procces
        this.$emit("onSubmitStep", {
          code: this.code,
          abbreviation: this.abbreviation,
          description: this.description,
          projectManager: this.projectManager,
          client: this.client
        });
      }
    }
  },
  computed: {
    ...mapGetters([
      "projectManagers",
      "isFetchingProjectManagers",
      "isFetchingProjectManagersFail",
      "clients",
      "isFetchingClients",
      "isFetchingClientsFail",
      "isTochedFormProject"
    ])
  },
  methods: {
    onSelect(value, itemName) {
      this.$data[itemName] = value;
      if ("id" in value) {
        this.$data[`${itemName}_hidden`] = value.id;
      }
    },
    // Validators
    isInvalidSelectNull(selectedItems) {
      return this.isTochedFormProject && selectedItems === null;
    }
  },
  components: {
    "trazo-form-input": TrazoFormInput,
    "trazo-form-label-slot": TrazoFormLabelSlot
  }
};
</script>
