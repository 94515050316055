<template>
  <!--begin: Wizard Step 2-->
  <div class="pb-5 row justify-content-center" data-wizard-type="step-content">
    <div class="col-lg-7 m-auto">
      <trazo-form-label-slot :required="true" label="Orden del cliente">
        <div
          :class="{
            invalid: isInvalidSelectNull(clientOrder)
          }"
        >
          <div
            class="fv-plugins-message-container"
            v-if="isInvalidSelectNull(clientOrder)"
          >
            <div class="fv-help-block">El orden del cliente es obligatorio</div>
          </div>
          <a-multiselect
            name="clientOrder"
            track-by="id"
            label="social_name"
            v-model="clientOrder"
            placeholder="Seleccione Orden del cliente"
            :options="clients"
            :allow-empty="true"
            @select="onSelect($event, 'clientOrder')"
          />
          <!-- cambiar a clientOrders -->
        </div>
      </trazo-form-label-slot>
      <trazo-form-input
        id="input-itemsManager"
        v-model="itemsManager"
        name="itemsManager"
        ref="itemsManager"
        label="Maestro de items"
        :max="100"
        :required="true"
        placeholder="Ingrese código de Maestro de items"
        description="Ingrese el código ó cargue el Maestro de Ítem utilizado en el Presupuesto."
      >
        <b-button-group class="mr-1">
          <b-button title="Save file" @click="onButtonFileItemsManager">
            <b-icon icon="cloud-upload" aria-hidden="true"></b-icon>
          </b-button>
          <input
            ref="fileItemsManager"
            class="trazo-file-hidde"
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            @change="onFileChanged($event, 'fileItemsManager', 'itemsManager')"
          />
        </b-button-group>
      </trazo-form-input>
    </div>
  </div>
  <!--end: Wizard Step 2-->
</template>

<script>
import "vue-multiselect/dist/vue-multiselect.min.css";

import TrazoFormInput from "@/view/content/TrazoFormInput";
import TrazoFormLabelSlot from "@/view/content/TrazoFormLabelSlot";
import { mapGetters } from "vuex";

export default {
  props: ["submited"],
  data() {
    return {
      clientOrder: null,
      clientOrders: [],
      itemsManager: "",
      fileItemsManager: null
    };
  },
  name: "Project-Step-2",
  computed: {
    ...mapGetters(["isTochedFormProject", "clients"])
  },
  watch: {
    // This would be called anytime the value of title changes
    submited(newValue, oldValue) {
      if (newValue != oldValue && newValue === 2) {
        // Send data to procces
        this.$emit("onSubmitStep", {
          clientOrder: this.clientOrder,
          itemsManager: this.itemsManager,
          fileItemsManager: this.fileItemsManager
        });
      }
    }
  },
  methods: {
    onSelect(value, itemName) {
      this.$data[itemName] = value;
    },
    // events
    onButtonFileItemsManager() {
      this.$refs.fileItemsManager.click();
    },
    onFileChanged(e, itemName, inputName = null) {
      this.$data[itemName] = e.target.files[0];
      if (inputName) {
        this.$data[inputName] = this.$data[itemName].name;
      }
    },
    // Validators
    isInvalidSelectNull(selectedItems) {
      return this.isTochedFormProject && selectedItems === null;
    }
  },
  components: {
    "trazo-form-input": TrazoFormInput,
    "trazo-form-label-slot": TrazoFormLabelSlot
  }
};
</script>
