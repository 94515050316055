<template>
  <div class="card card-custom">
    <portal to="subheader_actions">
      <b-button
        @click="$router.push({ name: 'project_wizard' })"
        class="mr-2"
        disabled
      >
        <span class="svg-icon svg-icon-primary">
          <inline-svg src="media/svg/icons/General/Save.svg" />
        </span>
        Guardar
      </b-button>
      <b-button class="mr-2">
        <span class="svg-icon svg-icon-primary">
          <inline-svg src="media/svg/icons/Navigation/Close.svg" />
        </span>
        Cancelar
      </b-button>
    </portal>
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-1"
        id="kt_wizard_v1"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav border-bottom">
          <div class="wizard-steps p-8 p-lg-10">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-label">
                <inline-svg
                  height=".8em"
                  width=".8em"
                  class="bi-folder-fill wizard-icon b-icon bi"
                  fill="currentColor"
                  src="media/svg/icons/Home/Home.svg"
                />
                <h3 class="wizard-title">Datos Generales</h3>
              </div>
              <i class="wizard-arrow flaticon2-next"></i>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <inline-svg
                  height=".7em"
                  width=".7em"
                  class="bi-folder-fill wizard-icon b-icon bi"
                  fill="currentColor"
                  src="media/svg/icons/Files/Selected-file.svg"
                />
                <h3 class="wizard-title">Sustento</h3>
              </div>
              <i class="wizard-arrow flaticon2-next"></i>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <inline-svg
                  height=".7em"
                  width=".7em"
                  class="bi-folder-fill wizard-icon b-icon bi"
                  fill="currentColor"
                  src="media/svg/icons/Home/Commode2.svg"
                />
                <h3 class="wizard-title">Estructura</h3>
              </div>
              <i class="wizard-arrow flaticon2-next"></i>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <inline-svg
                  height=".7em"
                  width=".7em"
                  class="bi-folder-fill wizard-icon b-icon bi"
                  fill="currentColor"
                  src="media/svg/icons/Shopping/Calculator.svg"
                />
                <h3 class="wizard-title">Config. Contable</h3>
              </div>
              <i class="wizard-arrow flaticon2-next"></i>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <inline-svg
                  height=".7em"
                  width=".7em"
                  class="bi-folder-fill wizard-icon b-icon bi"
                  fill="currentColor"
                  src="media/svg/icons/Shopping/Money.svg"
                />
                <h3 class="wizard-title">Políticas de Gasto</h3>
              </div>
              <i class="wizard-arrow last flaticon2-next"></i>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav-->

        <!--begin: Wizard Body-->
        <div class="my-10 px-8 my-lg-15 px-lg-10">
          <div class="row justify-content-center">
            <h3 class="col-xxl-9 mb-10 font-weight-bold text-dark">
              {{ getTitle() }}
            </h3>
            <!--begin: Wizard Form-->
            <form class="form col-xxl-12" id="kt_form" novalidate="novalidate">
              <!--begin: Wizard Step 1 -->
              <project-step-1
                :submited="submited"
                v-on:onSubmitStep="onSubmitStep"
              />
              <!-- end: Wizard Step 1-->

              <!--begin: Wizard Step 2 -->
              <project-step-2
                :submited="submited"
                v-on:onSubmitStep="onSubmitStep"
              />
              <!-- end: Wizard Step 2-->

              <!--begin: Wizard Step 3 -->
              <project-step-3
                :submited="submited"
                v-on:onSubmitStep="onSubmitStep"
              />
              <!-- end: Wizard Step 3-->

              <!--begin: Wizard Step 4 -->
              <project-step-4
                :submited="submited"
                v-on:onSubmitStep="onSubmitStep"
              />
              <!-- end: Wizard Step 4-->

              <!--begin: Wizard Step 5 -->
              <project-step-5
                :submited="submited"
                v-on:onSubmitStep="onSubmitStep"
              />
              <!-- end: Wizard Step 5-->

              <!--begin: Wizard Actions -->
              <div class="d-flex justify-content-between pt-10 col-xxl-12">
                <div class="mr-2">
                  <button
                    class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-prev"
                  >
                    Anterior
                  </button>
                </div>
                <div>
                  <button
                    class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-submit"
                    type="submit"
                  >
                    Submit
                  </button>
                  <button
                    class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-next"
                  >
                    Siguiente
                  </button>
                </div>
              </div>
              <!--end: Wizard Actions -->
              <div class="form-check"></div>
            </form>
            <!--end: Wizard Form-->
          </div>
        </div>
        <!--end: Wizard Body-->
      </div>
    </div>
    <!--end: Wizard-->
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-1.scss";
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import "vue-multiselect/dist/vue-multiselect.min.css";
import { mapGetters } from "vuex";

/* Form validations plugins */
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
// import Icon from "@/assets/plugins/formvalidation/dist/es6/plugins/Icon";
import {
  UPDATE_TOUCHED_FORM,
  UPDATE_PROJECT
} from "@/core/services/store/project.module";
import { GET_PROJECT_MANAGERS } from "@/core/services/store/projectManager.module";
import { GET_CLIENTS } from "@/core/services/store/client.module";

// Steps
import ProjectStep1 from "./steps/Step1";
import ProjectStep2 from "./steps/Step2";
import ProjectStep3 from "./steps/Step3";
import ProjectStep4 from "./steps/Step4";
import ProjectStep5 from "./steps/Step5";

export default {
  data() {
    return {
      submited: 0, // Step validate has been submited
      currentStep: 1,
      // Step 3
      showModalFormSubProject: false,
      currentSubProject: null,
      fields: [
        { key: "actions", label: "Acciones" },
        { key: "code", label: "Código", sortable: true },
        { key: "detail", label: "Detalle", sortable: true },
        { key: "quantity", label: "Cantidad", sortable: true },
        { key: "state", label: "Estado", sortable: true }
      ],
      subProjects: [
        {
          code: "123",
          detail: "Mi partida 1",
          quantity: 1,
          state: "Activo"
        },
        {
          code: "1233",
          detail: "Mi partida 2",
          quantity: 2,
          state: "Activo"
        },
        {
          code: "1234",
          detail: "Mi partida 3",
          quantity: 3,
          state: "Activo"
        }
      ],
      fieldsPages: [15, 20, 25, 30, 40, 50],
      itemsPerPage: "25",
      currentPage: 1,
      searchWord: "",
      // End Subproject Detail

      // End Step 3
      /** Validators */
      validations: [],
      isTouchForm: false
    };
  },
  computed: {
    ...mapGetters(["project"])
  },
  name: "Wizard-1",
  mounted() {
    this.$store.dispatch(GET_PROJECT_MANAGERS);
    this.$store.dispatch(GET_CLIENTS);
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Ingeniería" },
      { title: "Nuevo Proyecto" }
    ]);
    this.initValidations();
    this.initWizard();
  },
  methods: {
    onSelect(value, itemName) {
      this.$data[itemName] = value;
    },
    getTitle() {
      const titles = [
        "DATOS GENERALES DEL PROYECTO",
        "SUSTENTACIÓN DEL PROYECTO",
        "ESTRUCTURACIÓN DEL PROYECTO: [Partidas]",
        "CONFIGURACIÓN CONTABLE DEL PROYECTO",
        "POLÍTICAS DE GASTO DEL PROYECTO"
      ];
      return titles[this.currentStep - 1];
    },
    submit: function (e) {
      e.preventDefault();
      Swal.fire({
        title: "",
        text: "The application has been successfully submitted!",
        icon: "success",
        confirmButtonClass: "btn btn-secondary"
      });
    },
    calculateStatus(status) {
      return status === true ? "ACTIVO" : "INACTIVO";
    },
    initWizard() {
      // Initialize form wizard
      const wizard = new KTWizard("kt_wizard_v1", {
        startStep: 1, // initial active step number
        clickableSteps: false // allow step clicking
      });
      // Validation before going to next page
      wizard.on("beforeNext", (/* wizardObj */) => {
        // console.log({wizardObj});
        // validate the form and use below function to stop the wizard's step
        // wizardObj.stop();
      });
      // Change event
      wizard.on("change", (wizardObj) => {
        if (wizardObj.getStep() > wizardObj.getNewStep()) {
          return; // Skip if stepped back
        }
        // Validate form before change wizard step
        var validator = this.validations[wizardObj.getStep() - 1]; // get validator for currnt step
        if (validator) {
          validator.validate().then((status) => {
            if (status == "Valid") {
              this.submited = wizardObj.getStep();
              this.$store.dispatch(UPDATE_TOUCHED_FORM, false);
              this.currentStep = wizardObj.getNewStep();
              console.log("this.currentStep", this.currentStep);
              wizardObj.goTo(wizardObj.getNewStep());
              KTUtil.scrollTop();
            } else {
              this.$store.dispatch(UPDATE_TOUCHED_FORM, true);
              Swal.fire({
                text:
                  "Lo sentimos, parece que se han detectado algunos errores. Vuelve a intentarlo.",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "¡Ok lo tengo!",
                customClass: {
                  confirmButton: "btn font-weight-bold btn-light"
                }
              }).then(function () {
                KTUtil.scrollTop();
              });
            }
          });
        } else {
          this.$store.dispatch(UPDATE_TOUCHED_FORM, false);
          wizardObj.goTo(wizardObj.getNewStep());
          KTUtil.scrollTop();
        }
        return false; // Do not change wizard step, further action will be handled by he validator
        // setTimeout(() => {
        //   KTUtil.scrollTop();
        // }, 500);
      });
    },
    initValidations() {
      const wizard_form = KTUtil.getById("kt_form");
      try {
        const fieldsValidation = {
          "step-1": {
            code: {
              validators: {
                notEmpty: {
                  message: "El código es obligatorio"
                },
                stringLength: {
                  max: 31,
                  message: "El código debe tener menos de 30 caracteres."
                }
              }
            },
            client_hidden: {
              validators: {
                notEmpty: {
                  message: "El cliente es obligatorio"
                }
              }
            },
            projectManager_hidden: {
              validators: {
                notEmpty: {
                  message: "El Jefe de proyecto es obligatorio"
                }
              }
            },
            abbreviation: {
              validators: {
                notEmpty: {
                  message: "La abreviatura es obligatorio"
                },
                stringLength: {
                  max: 31,
                  message: "La abreviatura debe tener menos de 30 caracteres."
                }
              }
            },
            description: {
              validators: {
                notEmpty: {
                  message: "La descripción es obligatorio"
                },
                stringLength: {
                  max: 100,
                  message: "La descripción debe tener menos de 100 caracteres."
                }
              }
            }
          },
          "step-2": {
            itemsManager: {
              validators: {
                notEmpty: {
                  message: "El maetro de items es obligatorio"
                },
                stringLength: {
                  max: 101,
                  message: "El maestro de items tener menos de 100 caracteres."
                }
              }
            }
          }
        };
        this.validations = Object.values(fieldsValidation).map((fields) => {
          return formValidation(wizard_form, {
            fields,
            plugins: {
              trigger: new Trigger(),
              submitButton: new SubmitButton(),
              bootstrap: new Bootstrap()
            }
          });
        });
      } catch (error) {
        console.log("error", error);
      }
    },
    // events
    onButtonFileItemsManager() {
      this.$refs.fileItemsManager.click();
    },
    onFileChanged(e, itemName, inputName = null) {
      this.$data[itemName] = e.target.files[0];
      if (inputName) {
        this.$data[inputName] = this.$data[itemName].name;
      }
    },
    // Validators
    isInvalidSelectNull(selectedItems) {
      return this.isTouchForm && selectedItems === null;
    },
    // Step 3
    editSubProject(data) {
      this.currentSubProject = data.item;
      this.showModalFormSubProject = true;
    },
    onSubmitStep(data) {
      this.$store.dispatch(UPDATE_PROJECT, { ...this.project, ...data });
    }
  },
  components: {
    ProjectStep1,
    ProjectStep2,
    ProjectStep3,
    ProjectStep4,
    ProjectStep5
  }
};
</script>
